import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, Renderer2, SimpleChanges } from '@angular/core';
import { XMLParser } from 'fast-xml-parser';
import { translationsDe } from './i18n/de';
import { translationsEn } from './i18n/en';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-e-invoice-viewer',
	template: '',
	styleUrls: ['./e-invoice-viewer.component.scss']
})
export class EInvoiceViewerComponent implements OnChanges {
	@Input() srcUrl!: string;
	@Input() lang: 'de' | 'en' = 'de';
	@Input() showIds: boolean = false;
	@Output() onError: EventEmitter<void> = new EventEmitter();

	xmlContent: string | undefined = undefined;
	private readonly xmlParser: XMLParser = new XMLParser();
	private readonly domParser: DOMParser = new DOMParser();
	private translations: any = translationsDe;

	constructor(
		private readonly renderer: Renderer2,
		private readonly elementRef: ElementRef,
		private readonly http: HttpClient
	) {}

	ngOnChanges(changes: SimpleChanges) {
		const srcUrl = changes['srcUrl'];
		if (srcUrl.currentValue) {
			this.http.get(srcUrl.currentValue, { responseType: 'blob' }).subscribe({
				next: (fileBlob) => {
					fileBlob.text().then((content) => {
						this.xmlContent = content;
						this.displayXML();
					});
				},
				error: (err) => {
					console.error(err);
					// TODO: Error handling here
				}
			});
		}
		const langChanges = changes['lang'];
		if (langChanges) {
			switch (langChanges.currentValue) {
				case 'en':
					this.translations = translationsEn;
					break;
				case 'de':
				default:
					this.translations = translationsDe;
					break;
			}
			this.displayXML();
		}
	}

	private displayXML() {
		if (!this.xmlContent) return;
		const parsedXml = this.xmlParser.parse(this.xmlContent);
		try {
			let found = false;
			for (let key in parsedXml) {
				if (key.includes('CrossIndustryInvoice')) {
					// Display CII
					this.transformAndDisplay(this.xmlContent, 'cii-xr.sef.json');
					found = true;
				} else if (key.includes('SCRDMCCBDACIOMessageStructure')) {
					// Display CIO
					this.transformAndDisplay(this.xmlContent, 'cio-xr.sef.json');
					found = true;
				} else if (key.includes('Invoice')) {
					// Display UBL
					this.transformAndDisplay(this.xmlContent, 'ubl-xr.sef.json');
					found = true;
				} else if (key.includes('CreditNote')) {
					// Display UBLCN
					this.transformAndDisplay(this.xmlContent, 'ubl-creditnote-xr.sef.json');
					found = true;
				}
			}
			if (!found) {
				// TODO: Show message to user
				console.error(
					'File format not recognized',
					'Is it a UBL 2.1 or UN/CEFACT 2016b XML file or PDF you are trying to open?'
				);
				this.onError.emit()
			}
		} catch (err) {
			// TODO: Show message to user
			console.error(err);
			this.onError.emit()
		}
	}

	transformAndDisplay(content: string, stylesheetFileName: string) {
		const namespaces: any = {
			rsm: 'urn:un:unece:uncefact:data:standard:ReusableAggregateBusinessInformationEntity:100',
			ram: 'urn:un:unece:uncefact:data:standard:ReusableAggregateBusinessInformationEntity:100'
		};

		// Create a namespace resolver function
		function nsResolver(prefix: string | null): string | null {
			if (prefix === null) return null;
			return namespaces[prefix] || null;
		}

		const doc = this.domParser.parseFromString(content, 'application/xml');
		//cbc:InvoiceTypeCode for bis billing, ubl order would be <cbc:ProfileID>urn:fdc:peppol.eu:poacc:bis:ordering:3</cbc:ProfileID>
		const typecode = doc.evaluate(
			'//rsm:ExchangedDocument/ram:TypeCode',
			doc,
			nsResolver,
			XPathResult.STRING_TYPE,
			null
		).stringValue;

		const isOrder = typecode == '220' || typecode == '231';

		return SaxonJS.transform(
			{
				stylesheetLocation: `assets/xslt/${stylesheetFileName}`,
				sourceText: content,
				destination: 'serialized'
			},
			'async'
		).then((output: any) => {
			let xrXML = output.principalResult;

			if (isOrder) {
				this.translations['bt1'] = this.translations['bt1_order']; // Rechnungsnummer -> Bestellnummer
				this.translations['bt2'] = this.translations['bt2_order']; // Rechnungsdatum
				this.translations['bt3'] = this.translations['bt3_order']; // Rechnungart
				this.translations['bg22'] = this.translations['bg22_order']; // Gesamtbeträge der Rechnung
				this.translations['bt25'] = this.translations['bt25_order']; // Rechnungsnummer
				this.translations['bt26'] = this.translations['bt26_order']; // Rechnungsdatum
				this.translations['details'] = this.translations['details_order']; // Rechnungsdaten
			}

			SaxonJS.transform(
				{
					stylesheetLocation: 'assets/xslt/xrechnung-html.smivl.sef.json',
					sourceText: xrXML,
					destination: 'serialized',
					stylesheetParams: {
						isOrder,
						showIds: this.showIds,
						'Q{}i18n': this.translations
					}
				},
				'async'
			).then((response: any) => {
				if (this.elementRef.nativeElement.firstChild) {
					this.renderer.removeChild(this.elementRef.nativeElement, this.elementRef.nativeElement.firstChild);
				}
				this.renderer.appendChild(
					this.elementRef.nativeElement,
					this.domParser.parseFromString(response.principalResult, 'text/html').body
				);
			});
		});
	}

	@HostListener('document:click', ['$event'])
	handleMenuItemClick(event: MouseEvent) {
		const target = event.target as HTMLElement;
		if (target.matches('button.tab')) {
			document.getElementsByClassName('btnAktiv')[0]?.classList.remove('btnAktiv');
			target.classList.add('btnAktiv');

			const visibleTabContent = document.getElementsByClassName('divShow')[0];
			if (visibleTabContent) {
				this.renderer.setStyle(visibleTabContent, 'display', 'none');
				this.renderer.removeClass(visibleTabContent, 'divShow');
			}
			switch (target.id) {
				case 'menueUebersicht':
					const uebersicht = document.getElementById('uebersicht');
					this.renderer.setStyle(uebersicht, 'display', 'block');
					this.renderer.addClass(uebersicht, 'divShow');
					break;
				case 'menueDetails':
					const details = document.getElementById('details');
					this.renderer.setStyle(details, 'display', 'block');
					this.renderer.addClass(details, 'divShow');
					break;
				case 'menueZusaetze':
					const zusaetze = document.getElementById('zusaetze');
					this.renderer.setStyle(zusaetze, 'display', 'block');
					this.renderer.addClass(zusaetze, 'divShow');
					break;
				case 'menueAnlagen':
					const anlagen = document.getElementById('anlagen');
					this.renderer.setStyle(anlagen, 'display', 'block');
					this.renderer.addClass(anlagen, 'divShow');
					break;
				case 'menueLaufzettel':
					const laufzettel = document.getElementById('laufzettel');
					this.renderer.setStyle(laufzettel, 'display', 'block');
					this.renderer.addClass(laufzettel, 'divShow');
					break;
			}
		}
	}
}
