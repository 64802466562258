export var translationsEn = {
  "File": "File",
  "Print": "Print",
  "Exit": "Exit",
  "Edit": "Edit",
  "Help": "Help",
  "About": "About",
  "Open File": "Open File",
  "Language": "Language",
  "English": "English",
  "Cut": "Cut",
  "Copy": "Copy",
  "Paste": "Paste",
  "Delete": "Delete",
  "Select all": "Select All",
  "appName": "E-Invoice Viewer",
  "Login" : "Login",
  "Logout": "Logout",
  "Validate": "Validate",
  "Demo User":"Demo User",
  "Username":"Username",
  "Password":"Password",
  "Privacy Policy":"Privacy Policy",
  "Subscription":"Subscription",
  "Accept":"Accept",
  "en": "English",
  "de": "German",
  "fr": "French",
  "privacyPolicyText": "Please validate only anonymized test data without any personal information (sender, recipient, bank credentials) unless you have a subscription with a contract for commissioned data processing.",
  "bt10" :"Routing ID",
  "bt44" :"Name",
  "title" :"XRechnung",
  "overview" :"Overview",
  "items" :"Items",
  "information" :"Information",
  "attachments" :"Attachments",
  "history" :"History",
  "disclaimer" :"We accept no liability for the correctness of the data",
  "recipientInfo" :"Buyer Information",
  "bt50" :"Street / house number",
  "bt51" :"PO Box",
  "bt163" :"Address Addition",
  "bt53" :"Postcode",
  "bt52" :"Place",
  "bt54" :"State/Province",
  "bt55" :"Country",
  "bt46" :"ID",
  "bt46-id" :"ID scheme",
  "bt56" :"Name",
  "bt57" :"Phone",
  "bt58" :"E-mail address",
  "bt27" :"Company name",
  "bt35" :"Street / house number",
  "bt36" :"PO Box",
  "bt162" :"Address Addition",
  "bt38" :"Code postal",
  "bt37" :"Place",
  "bt39" :"State/Province",
  "bt40" :"Country code",
  "bt29" :"ID",
  "bt29-id" :"ID scheme",
  "bt41" :"Name",
  "bt42" :"Phone",
  "bt43" :"E-mail address",
  "bt1" :"Invoice number",
  "bt1_order" :"Order number",
  "bt2" :"Invoice date",
  "bt2_order" :"Order date",
  "details" :"Invoice details",
  "details_order" :"Order details",
  "period" :"Billing period",
  "bt3" :"Invoice type",
  "bt3_order" :"Document type",
  "bt5" :"Currency",
  "bt73" :"from",
  "bt74" :"to",
  "bt11" :"Project number",
  "bt12" :"Contract Number",
  "bt13" :"Order number",
  "bt14" :"Order number",
  "bt25" :"Invoice number",
  "bt25_order" :"Order number",
  "bt26" :"Invoice date",
  "bt26_order" :"Order date",
  "bg22" :"Total amounts of the invoice",
  "bg22_order" :"Total amounts of the order",
  "bt106" :"Line total",
  "bt107" :"Total discounts",
  "bt108" :"Total charges",
  "bt109" :"Grand total",
  "bt110" :"VAT amount",
  "bt111" :"Total VAT",
  "bt112" :"Grand total",
  "bt113" :"Amount paid",
  "bt114" :"rounding amount",
  "bt115" :"Amount Due",
  "bg23" :"Breakdown of VAT at invoice level",
  "bt118" :"VAT category",
  "bt116" :"Grand total",
  "bt119" :"VAT rate",
  "bt117" :"VAT amount",
  "bt120" :"Reason for exemption:",
  "bt121" :"ID for the exemption reason:",
  "bg20" :"Allowance at invoice level",
  "bt95" :"VAT category of the discount:",
  "bt93" :"Basic amount",
  "bt94" :"Percentage",
  "bt92" :"Allowance",
  "bt96" :"VAT rate on allowance",
  "bt97" :"Reason for the allowance",
  "bt98" :"Document level allowance reason code",
  "bg21" :"Charge amount at invoice level",
  "bt102" :"VAT category of the charge amount",
  "bt100" :"Basic amount",
  "bt101" :"Percentage",
  "bt99" :"Charge amount",
  "bt103" :"VAT rate of the charge amount",
  "bt104" :"Reason for the charge amount:",
  "bt105" :"Document level charge reason code",
  "bt20" :"Discount; other payment terms",
  "bt9" :"Due date",
  "bt81" :"Code for the means of payment",
  "bt82" :"Means of payment",
  "bt83" :"Usage",
  "bg18" :"Card information",
  "bt87" :"Card number",
  "bt88" :"Card holder",
  "bg19" :"Direct debit",
  "bt89" :"Mandate Reference No",
  "bt91" :"IBAN",
  "bt90" :"Creditor ID",
  "bg17" :"Transfer",
  "bt85" :"Account holder",
  "bt84" :"IBAN",
  "bt86" :"BIC",
  "bg1" :"Invoice Comment",
  "bt21" :"Subject",
  "bt22" :"Comment",
  "bt126" :"Line",
  "bt127" :"Free text",
  "bt128" :"Object ID",
  "bt128-id" :"Object ID schema",
  "bt132" :"Order line number",
  "bt133" :"Account assignment information",
  "bg26" :"Billing period",
  "bt134" :"from",
  "bt135" :"to",
  "bg29" :"Price details",
  "bt129" :"Quantity",
  "bt130" :"Unit",
  "bt146" :"Unit price (net)",
  "bt131" :"Total price (net)",
  "bt147" :"Discount (net)",
  "bt148" :"Net list price",
  "bt149" :"Number of units",
  "bt150" :"Unit of measure code",
  "bt151" :"VAT",
  "bt152" :"VAT rate in percent",
  "bg27" :"Allowances at line level",
  "bt137" :"Basic amount (net)",
  "bt138" :"Percentage",
  "bt136" :"Allowance (net)",
  "bt139" :"Reason for allowance",
  "bt140" :"Code for the reason for the estate",
  "bg28" :"Charges at invoice line level",
  "bt142" :"Basic amount (net)",
  "bt143" :"percentage",
  "bt141" :"Charge amount (net)",
  "bt144" :"Reason for the allowance",
  "bt145" :"Reason code",
  "bg31" :"Item Information",
  "bt153" :"Name",
  "bt154" :"Description",
  "bt155" :"Part number",
  "bt156" :"Customer''s material number",
  "bg32" :"Article properties",
  "bt157" :"Item ID",
  "bt157-id" :"Item ID schema",
  "bt158" :"Item classification code",
  "bt158-id" :"Identifier to form the schema",
  "bt157-vers-id" :"Version for creating the schema",
  "bt159" :"Country of origin code",
  "bg4" :"Seller Information",
  "bt28" :"Differing trade name",
  "bt34" :"Electronic address",
  "bt34-id" :"Electronic address scheme",
  "bt30" :"Register number",
  "bt31" :"VAT ID",
  "bt32" :"Tax ID",
  "bt32-schema" :"Tax ID scheme",
  "bt33" :"Further legal information",
  "bt6" :"VAT currency code",
  "bg11" :"seller''s tax representative",
  "bt62" :"Name",
  "bt64" :"Street / house number",
  "bt65" :"PO Box",
  "bt164" :"Address Addition",
  "bt67" :"Postcode",
  "bt66" :"Place",
  "bt68" :"State/Province",
  "bt69" :"Country code",
  "bt63" :"VAT ID",
  "bg7" :"Buyer Information",
  "bt45" :"Differing trade name",
  "bt49" :"E-mail address",
  "bt49-id" :"Electronic address scheme",
  "bt47" :"Register number",
  "bt47-id" :"Scheme of register/register number",
  "bt48" :"VAT ID",
  "bt7" :"VAT payday date",
  "bt8" :"VAT Statement Date Code",
  "bt19" :"Account assignment information",
  "bg13" :"Shipping information",
  "bt71" :"Identification of the place of delivery",
  "bt71-id" :"ID scheme",
  "bt72" :"Date of delivery",
  "bt70" :"Recipient name",
  "bt75" :"Street / house number",
  "bt76" :"PO Box",
  "bt165" :"Address Addition",
  "bt78" :"Code postal",
  "bt77" :"Place",
  "bt79" :"State/Province",
  "bt80" :"Country",
  "bt17" :"Assignment number",
  "bt15" :"Receipt confirmation ID",
  "bt16" :"Dispatch note ID",
  "bt23" :"Process ID",
  "bt24" :"Specification ID",
  "bt18" :"Object ID",
  "bt18-id" :"Object ID schema",
  "bg10" :"Payee other than Seller",
  "bt59" :"Name",
  "bt60" :"ID",
  "bt60-id" :"ID scheme",
  "bt61" :"Register number",
  "bt61-id" :"Scheme of register/register number",
  "bg24" :"Documents justifying the invoice",
  "bt122" :"ID",
  "bt123" :"Description",
  "bt124" :"Reference (e.g. Internet address)",
  "bt125" :"Attachment",
  "bt125-format" :"Format of attachment",
  "bt125-name" :"Name of attachment",
  "historyDate" :"Date/time",
  "historySubject" :"Subject",
  "historyText" :"Text",
  "historyDetails" :"Details:",
  "licenseText": "Apache 2 license",
  "payment" :"Payment details",
  "contract_information" :"Contract Information",
  "preceding_invoice_reference" :"Previous invoices"
}
