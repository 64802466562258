import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule, NgbNavModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { NumberFormatPipe } from './pipes/number-format/number-format.pipe';
import { SafeHTMLPipe } from '@app/shared/pipes/safeHTML/safe-html.pipe';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';

import { TabSetComponent } from '@app/shared/components/tab-set/tab-set.component';
import { InputComponent } from '@app/shared/components/input/input.component';

import { ButtonDropdownComponent } from './components/button-dropdown/button-dropdown.component';
import { ButtonComponent } from '@app/shared/components/button/button.component';
import { CheckboxComponent } from '@app/shared/components/checkbox/checkbox.component';
import { SwitchComponent } from '@app/shared/components/switch/switch.component';
import { EuconTemplateDirective } from './directives/eucon-template.directive';
import { TabItemDirective } from '@app/shared/components/tab-set/tab-item.directive';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { NavigationMenuButtonComponent } from './components/navigation-menu-button/navigation-menu-button.component';
import { RouterModule } from '@angular/router';
import { TableComponent } from './components/table/table.component';
import { TableCheckBoxComponent } from './components/table-check-box/table-check-box.component';
import { LookupComponent } from './components/lookup/lookup.component';
import { FileDropComponent } from './components/file-drop/file-drop.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ToastComponent } from '@app/shared/components/toast/toast.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule as pdf } from 'ng2-pdf-viewer';
import { PDFPreviewerComponent } from './components/pdfpreviewer/pdfpreviewer.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PageTitleButtonComponent } from './components/page-title/page-title-button/page-title-button.component';
import { PageTitleActionButtonComponent } from './components/page-title/page-title-action-button/page-title-action-button.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LinkNavigationComponent } from './components/navigation/link-navigation/link-navigation.component';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { UnionInvoiceNoteComponent } from './components/union-invoice-note/union-invoice-note.component';
import { UnionInvoiceNoteApiService } from '@app/shared/components/union-invoice-note/union-invoice-note.api.service';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';
import { TextareaAutoresizeDirective } from './directives/textarea-autoresize.directive';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { ModalOutletComponent } from '@app/shared/components/modal/modal-outlet.component';
import { XmlViewComponent } from './components/xml-view/xml-view.component';
import { EInvoiceViewerComponent } from '@app/shared/components/e-invoice-viewer/e-invoice-viewer.component';

const modules = [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	ScrollingModule,
	NgSelectModule,
	TranslateModule,
	NgbDropdownModule,
	NgbNavModule,
	DxDataGridModule,
	DxLoadIndicatorModule,
	NgbTooltipModule,
	RouterModule,
	NgxFileDropModule,
	pdf,
	DxDateBoxModule
];

const components = [
	ButtonDropdownComponent,
	ButtonComponent,
	CheckboxComponent,
	SwitchComponent,
	InputComponent,
	TabSetComponent,
	NavigationMenuComponent,
	NavigationMenuButtonComponent,
	TableComponent,
	TableCheckBoxComponent,
	LookupComponent,
	FileDropComponent,
	PageTitleComponent,
	PageTitleButtonComponent,
	PdfViewerComponent,
	XmlViewComponent,
	EInvoiceViewerComponent,
	ToastComponent,
	PDFPreviewerComponent,
	PageTitleComponent,
	PageTitleButtonComponent,
	PageTitleActionButtonComponent,
	NavigationComponent,
	LinkNavigationComponent,
	UnionInvoiceNoteComponent,
	TagInputComponent,
	ModalComponent,
	ModalOutletComponent
];

const directives = [EuconTemplateDirective, TabItemDirective];

const pipes = [NumberFormatPipe, SafeHTMLPipe];

@NgModule({
	declarations: [
		...components,
		...pipes,
		...directives,
		FileDropComponent,
		LoadingScreenComponent,
		FeatureFlagDirective,
		TagInputComponent,
		CopyToClipboardDirective,
		TextareaAutoresizeDirective
	],
	providers: [UnionInvoiceNoteApiService],
	imports: [...modules, NgbToastModule],
	exports: [
		...components,
		...modules,
		...pipes,
		...directives,
		LoadingScreenComponent,
		FeatureFlagDirective,
		CopyToClipboardDirective
	]
})
export class SharedModule {}
